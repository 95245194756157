import React, { useEffect } from 'react';
import ReactGA from 'react-ga';
import ConsentBar, { getCookieConsentValue } from "react-cookie-consent";

const Tracking = ({ children, enableCookieTracking = false }) => {

    const initTracking = (isConsent) => {
        const disableOptions = isConsent ? {} : { storage: 'none', storeGac: false };
        ReactGA.initialize('UA-185994418-1', {
            storage: 'none',
            gaOptions: {
                ...disableOptions
            }
        });
        ReactGA.pageview(window.location.pathname + window.location.search);
    }

    useEffect(() => {
        const isConsent = enableCookieTracking && !!getCookieConsentValue("bidmkr-cookie-consent");
        initTracking(isConsent);
    }, []);

    return (
        <div>
            {children}
            {enableCookieTracking && <ConsentBar
                location="bottom"
                buttonText="I'm ok with that"
                cookieName="bidmkr-cookie-consent"
                style={{ background: "#2B373B", zIndex: 10000 }}
                buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
                expires={3650}
                onAccept={() => initTracking(true)}
            >This website uses cookies to enhance the user experience.</ConsentBar>}
        </div>
    );
};

export default Tracking;
