import React from 'react';
import { createUseStyles } from "react-jss";

const useStyles = createUseStyles({
    LogoLoadingOverlay: ({ height, inline }) => {
        const width = height * 0.618 / 2.618
        return {
            position: inline ? 'relative' : 'absolute',
            top: inline ? null : '50%',
            left: inline ? null : '50%',
            width,
            height,
            marginLeft: 0,
            marginTop: 0,
            marginRight: width
        }
    },
    '@keyframes stretchDelay': {
        to: {
            transform: 'scaleY(6)',
        }
    },
    '@keyframes stretchDelay2': {
        to: {
            transform: 'scaleY(2.8)',
        }
    },
    root: ({ height, isStatic, color }) => {
        const width = height * 0.618
        const barWidth = width / 2.618;
        return {
            height: height,
            display: 'flex',
            flexDirection: 'row',
            position: 'absolute',
            '& > div': {
                position: 'absolute',
                backgroundColor: color ?? '#48AFF0',
                width: barWidth,
                bottom: height / 8,
            },
            '& .wick': {
                animation: isStatic ? '' : '$stretchDelay2 2s steps(5, end) infinite',
                transformOrigin: 'bottom left',
                height: isStatic ? '84%' : '30%',
                bottom: 0,
                left: barWidth / 2 - barWidth / 8 / 2,
                width: barWidth / 8
            },
            '& .second': {
                animation: isStatic ? '' : '$stretchDelay 2s steps(5, end) infinite',
                transformOrigin: 'bottom left',
                height: isStatic ? '58%' : '12%',
                left: 0,
            }
        }
    }
});

const LogoLoadingOverlay = ({ loading, size = 40, inline = true, isStatic = true, color }) => {
    const c = useStyles({
        height: size,
        inline,
        isStatic,
        color
    });

    return (loading || isStatic) ? <div className={c.LogoLoadingOverlay}>
        <div className={c.root}>
            <div className="wick"/>
            <div className="second"/>
        </div>
    </div> : null
};

export default LogoLoadingOverlay;
