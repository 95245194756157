/*eslint-disable*/
import React from "react";
import ReactGA from 'react-ga';

import DeleteIcon from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";
// react components for routing our app without refresh
import { Link } from "react-router-dom";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Tooltip from "@material-ui/core/Tooltip";

// @material-ui/icons
import { Apps, CloudDownload } from "@material-ui/icons";

// core components
import CustomDropdown from "components/CustomDropdown/CustomDropdown.jsx";
import Button from "components/CustomButtons/Button.jsx";

import headerLinksStyle from "assets/jss/material-kit-react/components/headerLinksStyle.jsx";

function HeaderLinks({ ...props }) {
  const { classes } = props;
  return (
    <List className={classes.list}>
        {/*<ListItem className={classes.listItem}>
        <CustomDropdown
          noLiPadding
          buttonText="Features"
          buttonProps={{
            className: classes.navLink,
            color: "transparent"
          }}
          buttonIcon={Apps}
          dropdownList={[
            <Link to="/features/trading" className={classes.dropdownLink}>
              Trading
            </Link>,
            <Link to="/features/exchanges" className={classes.dropdownLink}>
              Exchanges
            </Link>,
            <Link to="/features/security" className={classes.dropdownLink}>
              Security
            </Link>,
          ]}
        />
      </ListItem>*/}

        {/*<ListItem className={classes.listItem}>
          <Link to="/pricing" className={classes.navLink}>
              Pricing
          </Link>
      </ListItem>*/}
      <ListItem className={classes.listItem}>
          <Link className={classes.navLink} >
              Sign in
          </Link>
      </ListItem>
      <ListItem className={classes.listItem}>
        <Link className={classes.navLink} >
          Register
        </Link>
      </ListItem>
    {/*<ListItem className={classes.listItem}>*/}
    {/*    <Tooltip*/}
    {/*      id="twitter-tooltip"*/}
    {/*      title="Follow us on twitter"*/}
    {/*      placement={window.innerWidth > 959 ? "top" : "left"}*/}
    {/*      classes={{ tooltip: classes.tooltip }}*/}
    {/*    >*/}
    {/*      <ReactGA.OutboundLink*/}
    {/*          eventLabel="headerTwitter"*/}
    {/*          to="https://twitter.com/bidmkr"*/}
    {/*          target="_self"*/}
    {/*          color="transparent"*/}
    {/*          className={classes.navLink}*/}
    {/*      >*/}
    {/*        <i className={classes.socialIcons + " fab fa-twitter"} />*/}
    {/*      </ReactGA.OutboundLink>*/}
    {/*    </Tooltip>*/}
    {/*  </ListItem>*/}
        {/*
      <ListItem className={classes.listItem}>
        <Tooltip
          id="instagram-facebook"
          title="Follow us on facebook"
          placement={window.innerWidth > 959 ? "top" : "left"}
          classes={{ tooltip: classes.tooltip }}
        >
          <Button
            color="transparent"
            href="https://www.facebook.com/CreativeTim?ref=creativetim"
            target="_blank"
            className={classes.navLink}
          >
            <i className={classes.socialIcons + " fab fa-facebook"} />
          </Button>
        </Tooltip>
      </ListItem>
      */}
      {/*<ListItem className={classes.listItem}>*/}
      {/*  <Tooltip*/}
      {/*    id="telegram-tooltip"*/}
      {/*    title="Get in touch on telegram"*/}
      {/*    placement={window.innerWidth > 959 ? "top" : "left"}*/}
      {/*    classes={{ tooltip: classes.tooltip }}*/}
      {/*  >*/}
      {/*    <ReactGA.OutboundLink*/}
      {/*        eventLabel="headerTelegram"*/}
      {/*        to="https://t.me/bidmkr"*/}
      {/*        target="_self"*/}
      {/*        color="transparent"*/}
      {/*        className={classes.navLink}*/}
      {/*    >*/}
      {/*      <i className={classes.socialIcons + " fab fa-telegram"} />*/}
      {/*    </ReactGA.OutboundLink>*/}
      {/*  </Tooltip>*/}
      {/*</ListItem>*/}

    </List>
  );
}

export default withStyles(headerLinksStyle)(HeaderLinks);
