import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// @material-ui/icons
import TimelineIcon from '@material-ui/icons/Timeline';
import SettingsApplicationsIcon from '@material-ui/icons/SettingsApplications';
import DirectionsIcon from '@material-ui/icons/Directions';

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import InfoArea from "components/InfoArea/InfoArea.jsx";

import productStyle from "assets/jss/material-kit-react/views/landingPageSections/productStyle.jsx";
import Check from "@material-ui/icons/Check";
import Checkbox from "@material-ui/core/Checkbox";
import ReactGA from "react-ga";

import terminalImg from '../../../assets/img/terminal.png';
import analysisImg from '../../../assets/img/analysis.png';
import toolsImg from '../../../assets/img/tools.png';
import rulesImg from '../../../assets/img/rules.png';

class ProductSection extends React.Component {
  render() {
    const { classes } = this.props;
    return (
    <>
        <div className={classes.section}>
            <GridContainer justify="center">
                <GridItem xs={12} sm={12} md={8}>
                    <h2 className={classes.title}>Why its unique</h2>
                    <h5 className={classes.description}>
                        Bidmkr is the next generation, visual cryptocurrency trading and automation terminal designed for technical-, momentum- and swing-traders. Its like a personal assistant that puts your trading ideas into action. Precisely, reliable and without emotions.
                        <br/><br/>
                        Bidmkr relies on a unique automation technology paired with an intuitive trading interface. It includes a toolset of the best and most innovative professional and community-driven features. At any point you are in full control of your trades.
                        <br/><br/>
                        As a result, users of our service are taking more trading opportunities, experience less risk, execute trades better and more profitable.
                    </h5>
                </GridItem>
            </GridContainer>
            <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                    <h2 className={classes.title}>Features</h2>
                    <h3 className={classes.title} style={{ textAlign: "center" }}>Automate your trading strategy</h3>
                    <img src={terminalImg} alt={''}/>
                    <br /><br /><br /><br />
                    <h3 className={classes.title} style={{ textAlign: "center" }}>Autmated Technical Analysis & Tools</h3>
                    <img src={analysisImg} alt={''} style={{ borderRadius: 20, height: 260, marginLeft: 5, marginRight: 5 }}/>
                    <img src={toolsImg} alt={''} style={{ borderRadius: 20, height: 260, marginLeft: 5, marginRight: 5 }}/>
                    <br /><br /><br /><br />
                    <h3 className={classes.title} style={{ textAlign: "center" }}>Create automation rules</h3>
                    <img src={rulesImg} alt={''}/>
                    <br /><br /><br /><br />
                </GridItem>
            </GridContainer>

          <GridContainer>
              <GridItem xs={12} sm={12} md={4}>
                  <InfoArea
                      title="Technical Analysis"
                      description="Identify trends on the chart with various tools such as linear trend lines and price ranges. Design visually where your orders should be placed and how they should update over time. Don't worry about entering price levels manually anymore. It's all being done for you."
                      icon={TimelineIcon}
                      iconColor="primary"
                      vertical
                  />
              </GridItem>
              <GridItem xs={12} sm={12} md={4}>
                  <InfoArea
                      title="Strategies & Rules"
                      description="Rules let you define exactly how and when Bidmkr executes the trades for you. You can define different phases to organize your strategy throughout it's lifecycle. There are tons of features coming* that are not available anywhere else that will make your trading better."
                      icon={DirectionsIcon}
                      iconColor="primary"
                      vertical
                  />
              </GridItem>
              <GridItem xs={12} sm={12} md={4}>
                  <InfoArea
                      title="Automated Orders"
                      description="You are able to define order levels in a way that is not possible on exchanges directly. Bidmkr places and updates orders as markets move and time progresses based on your well defined strategy. There is no need to watch the chart since Bidmkr takes care of that."
                      icon={SettingsApplicationsIcon}
                      iconColor="primary"
                      vertical
                  />
              </GridItem>
          </GridContainer>
      </div>
      <div className={classes.section}>
        <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={8}>
            <h2 className={classes.title}>Key Benefits</h2>
            <div className={classes.tickList}>
                <p>
                    <Checkbox
                        checked={true}
                        checkedIcon={<Check className={classes.checkedIcon} />}
                        icon={<Check className={classes.uncheckedIcon} />}
                        classes={{
                            checked: classes.checked,
                            root: classes.checkRoot
                        }}
                    />
                    Manage complex trades setups intuitively on the chart
                </p>
                <p>
                    <Checkbox
                        checked={true}
                        checkedIcon={<Check className={classes.checkedIcon} />}
                        icon={<Check className={classes.uncheckedIcon} />}
                        classes={{
                            checked: classes.checked,
                            root: classes.checkRoot
                        }}
                    />
                    Let our automation engine place and update orders for you
                </p>
                <p>
                  <Checkbox
                      checked={true}
                      checkedIcon={<Check className={classes.checkedIcon} />}
                      icon={<Check className={classes.uncheckedIcon} />}
                      classes={{
                        checked: classes.checked,
                        root: classes.checkRoot
                      }}
                  />
                  Validate order-levels and -execution visually
                </p>
                <p>
                  <Checkbox
                      checked={true}
                      checkedIcon={<Check className={classes.checkedIcon} />}
                      icon={<Check className={classes.uncheckedIcon} />}
                      classes={{
                        checked: classes.checked,
                        root: classes.checkRoot
                      }}
                  />
                  Trade on your favourite exchanges via api
                </p>
                <p>
                    <Checkbox
                        checked={true}
                        checkedIcon={<Check className={classes.checkedIcon} />}
                        icon={<Check className={classes.uncheckedIcon} />}
                        classes={{
                            checked: classes.checked,
                            root: classes.checkRoot
                        }}
                    />
                    Keep full control over your trades. You make the decisions - not a bot
                </p>
                <p>
                  <Checkbox
                      checked={true}
                      checkedIcon={<Check className={classes.checkedIcon} />}
                      icon={<Check className={classes.uncheckedIcon} />}
                      classes={{
                        checked: classes.checked,
                        root: classes.checkRoot
                      }}
                  />
                  Enjoy an easier and more profitable trading experience
                </p>
            </div>
          </GridItem>
        </GridContainer>
      </div>
      <div className={classes.section}>
        <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={8}>
            <h2 className={classes.title}>Available soon</h2>
            <h5 className={classes.description}>
              This project is in internal testing and will soon go into a closed beta phase. If you are interested in joining us for beta, feel free to reach out to us. There will be a reward scheme for early users and contributors to a successful beta phase.
            </h5>
              {/*<ReactGA.OutboundLink*/}
              {/*    eventLabel="bodyTelegram"*/}
              {/*    to="https://t.me/bidmkr"*/}
              {/*    target="_self"*/}
              {/*    color="transparent"*/}
              {/*    className={classes.navLink}*/}
              {/*    style={{margin: 20, display: 'inline-block'}}*/}
              {/*>*/}
              {/*    <i className={classes.socialIcons + " fab fa-telegram"} />*/}
              {/*</ReactGA.OutboundLink>*/}

              {/*<ReactGA.OutboundLink*/}
              {/*    eventLabel="bodyTwitter"*/}
              {/*    to="https://twitter.com/bidmkr"*/}
              {/*    target="_self"*/}
              {/*    color="transparent"*/}
              {/*    className={classes.navLink}*/}
              {/*    style={{margin: 20, display: 'inline-block'}}*/}
              {/*>*/}
              {/*    <i className={classes.socialIcons + " fab fa-twitter"} />*/}
              {/*</ReactGA.OutboundLink>*/}
          </GridItem>
        </GridContainer>
      </div>
    </>);
  }
}

ProductSection.propTypes = {
  classes: PropTypes.object
};

export default withStyles(productStyle)(ProductSection);
