/*eslint-disable*/
import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { List, withStyles } from "@material-ui/core";

import footerStyle from "assets/jss/material-kit-react/components/footerStyle.jsx";

function Footer({ ...props }) {
  const { classes, whiteFont } = props;
  const footerClasses = classNames({
    [classes.footer]: true,
    [classes.footerWhiteFont]: whiteFont
  });
  return (
    <footer className={footerClasses}>
      <div className={classes.container}>
        <div>
          {/*<p>*/}
          {/*  We do not use Cookies in the public area of this site.*/}
          {/*</p>*/}
          <div className={classes.imprint}>
            {/*Imprint: Config.one GmbH, Rungestraße 20, 10179 Berlin, Germany. GF: Jakob Klippel.*/}
            {/*Eingetragen beim Amtsgericht Charlottenburg Berlin, HRB 147750B<br />*/}
            {/*Verantwortlicher für eigene Inhalte der Config.one GmbH gem. § 55 RStV: Jakob Klippel*/}
            {/*Umsatzsteuer-ID gemäß §27 a Umsatzsteuergesetz: DE287240639*/}
            {/*<br /><br />*/}
            <p>
              &copy; {1900 + new Date().getYear()} bidmkr.com
            </p>
          </div>

          <List className={classes.list}>
            {/*<ListItem className={classes.inlineBlock}>
              <Link to="/contact" className={classes.block}>
                Contact
              </Link>
            </ListItem>
            <ListItem className={classes.inlineBlock}>
              <Link to="/features/trading" className={classes.block}>
                Features
              </Link>
            </ListItem>
            <ListItem className={classes.inlineBlock}>
              <Link to="/pricing" className={classes.block}>
                Pricing
              </Link>
            </ListItem>
            <ListItem className={classes.inlineBlock}>
              <Link to="/imprint" className={classes.block}>
                Imprint
              </Link>
            </ListItem>
            <ListItem className={classes.inlineBlock}>
            <Link to="/data-policy" className={classes.block}>
              Data-Policy
            </Link>
          </ListItem>*/}
          </List>
        </div>
        {/*<div className={classes.right}>
          &copy; {1900 + new Date().getYear()} Bidmkr.com
        </div>*/}
      </div>
    </footer>
  );
}

Footer.propTypes = {
  classes: PropTypes.object.isRequired,
  whiteFont: PropTypes.bool
};

export default withStyles(footerStyle)(Footer);
