import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// @material-ui/icons

// core components
import Header from "components/Header/Header.jsx";
import Footer from "components/Footer/Footer.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import HeaderLinks from "components/Header/HeaderLinks.jsx";
import Parallax from "components/Parallax/Parallax.jsx";

import landingPageStyle from "assets/jss/material-kit-react/views/landingPage.jsx";

// Sections for this page
import ProductSection from "./Sections/ProductSection.jsx";

import logo from '../../assets/img/logo.png';
import ReactGA from "react-ga";
import LogoLoadingOverlay from "../../components/Logo/LogoLoadingOverlay";

const dashboardRoutes = [];

class LandingPage extends React.Component {
  render() {
    const { classes, ...rest } = this.props;
    return (
      <div>
        <Header
          color="transparent"
          routes={dashboardRoutes}
          brand={<><LogoLoadingOverlay loading={true} /><h3 style={{ fontWeight: 'bold' }}>Bidmkr</h3></>}
          rightLinks={<HeaderLinks />}
          fixed
          changeColorOnScroll={{
            height: 400,
            color: "white"
          }}
          {...rest}
        />
        <Parallax filter image={require("assets/img/bg.jpeg")}>
          <div className={classes.container}>
            <GridContainer>
              <GridItem xs={12} sm={12} md={9}>
                  <h1 className={classes.title}>Bidmkr</h1>
                  <h3 style={{ margin: 0 }}>Cryptocurrency Trading on Autopilot</h3>
                {/*<br />*/}
                {/*  <Button*/}
                {/*      style={{*/}
                {/*          marginRight: 20,*/}
                {/*          marginBottom: 10*/}
                {/*      }}*/}
                {/*      size="lg"*/}
                {/*      color="primary"*/}
                {/*  >*/}
                {/*      <ReactGA.OutboundLink*/}
                {/*          eventLabel="landingSignIn"*/}
                {/*          to="https://app.bidmkr.com"*/}
                {/*          target="_blank"*/}
                {/*          style={{color: '#FFFFFF'}}*/}
                {/*      >*/}
                {/*          Sign up for early access*/}
                {/*      </ReactGA.OutboundLink>*/}
                {/*  </Button>*/}
                  {/*<br />*/}
                  {/*<span style={{*/}
                  {/*    backgroundColor: '#151e26',*/}
                  {/*    padding: 5,*/}
                  {/*    fontSize: '0.9em',*/}
                  {/*    color: '#48aff0',*/}
                  {/*    fontWeight: "normal"*/}
                  {/*}}>*/}
                  {/*    Pre-register for upcoming beta access now. You will be notified about updates<br/> &nbsp;and the official beta launch. Registration is free.*/}
                  {/*</span>*/}


                  {/*<Button
                  color="secondary"
                  size="lg"
                  href="https://www.youtube.com/watch?v=dQw4w9WgXcQ&ref=creativetim"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i className="fas fa-play" />
                  Watch video
                </Button>*/}

              </GridItem>
            </GridContainer>
          </div>
        </Parallax>
        <div className={classNames(classes.main, classes.mainRaised)}>
          <div className={classes.container}>
            <ProductSection />
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

LandingPage.propTypes = {
  classes: PropTypes.object
};

export default withStyles(landingPageStyle)(LandingPage);
