import React from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { Router, Route, Switch } from "react-router-dom";

import "assets/scss/material-kit-react.scss?v=1.7.0";

import LandingPage from "views/LandingPage/LandingPage";
import Tracking from "./Tracking";
//import PricingPage from "./views/PricingPage/PricingPage";


var hist = createBrowserHistory();

ReactDOM.render(
  <Router history={hist}>
    <Switch>
        {/*<Route path="/pricing" >
            <PricingPage/>
        </Route>*/}
        <Route path="/">
            <Tracking enableCookieTracking={false}>
                <LandingPage/>
            </Tracking>
        </Route>
    </Switch>
  </Router>,
  document.getElementById("root")
);

