/*!

=========================================================
* Material Kit React - v1.7.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/material-kit-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import { title, primaryColor } from "assets/jss/material-kit-react.jsx";

const hexToRgb = input => {
  input = input + "";
  input = input.replace("#", "");
  let hexRegex = /[0-9A-Fa-f]/g;
  if (!hexRegex.test(input) || (input.length !== 3 && input.length !== 6)) {
    throw new Error("input is not a valid hex color.");
  }
  if (input.length === 3) {
    let first = input[0];
    let second = input[1];
    let last = input[2];
    input = first + first + second + second + last + last;
  }
  input = input.toUpperCase(input);
  let first = input[0] + input[1];
  let second = input[2] + input[3];
  let last = input[4] + input[5];
  return (
      parseInt(first, 16) +
      ", " +
      parseInt(second, 16) +
      ", " +
      parseInt(last, 16)
  );
};

const productStyle = {
  section: {
    padding: "50px 0",
    textAlign: "center"
  },
  title: {
    ...title,
    marginBottom: "1rem",
    marginTop: "30px",
    minHeight: "32px",
    textDecoration: "none",
    textAlign: 'center'
  },
  description: {
    fontWeight: 'normal',
    color: "#555",
    textAlign: 'left'
  },
  tickList: {
    color: '#555',
    // marginBottom: 40,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'left',
    '& p': {
      // width: 550,
      textAlign: 'left',
      display: 'block',
      fontSize: '17px',
      margin: 0,
      '& > span': {
        marginRight: 10
      }
    }
  },
  checkRoot: {
    padding: "14px",
    "&:hover": {
      backgroundColor:
          "rgba(" + hexToRgb(primaryColor) + ", 0.14) !important"
    }
  },
  checked: {
    color: primaryColor + "!important"
  },
  socialIcons: {
    position: "relative",
    fontSize: "40px !important",
    marginRight: "4px",
    color: '#0088cc',
    '&:hover': {
      opacity: 0.8
    }
  },
};

export default productStyle;
